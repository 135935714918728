import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";

import ServiceItem from "components/ServiceItem2";
import PageSection from "components/PageSection";
import { A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Services.scss";
import SectionHero from "components/SectionHero";

const Services = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    services,
    subheaderExtended,
    headerTitle,
    imageFileName: rootImageFileName,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHero
          header={rootHeader}
          subheader={rootSubHeader}
          subheaderExtended={subheaderExtended}
          headerTitle={headerTitle}
          imageFileName={rootImageFileName}
        />
      </Row>
      <Row className="text-center">
        <Swiper
          modules={[Pagination, A11y]}
          pagination={true}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          navigation
          className="service-swiper"
        >
          {services.map((service) => (
            <SwiperSlide key={service.header}>
              <ServiceItem {...service} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Row>
    </PageSection>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Services.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Services;
