import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Whatsapp = ({ link }) => <CircleIcon href={link} iconName="WhatsappIcon" />;

Whatsapp.propTypes = {
  link: PropTypes.string.isRequired,
};

export default Whatsapp;
