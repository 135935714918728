import React from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";
import Image from "components/Image";
import "./ServiceItem.scss";
import PortfolioDetailDialog from "./PortfolioDetailDialog";

const ServiceItem = ({ iconName, imageFileName, header, content, readMore }) => {
  let iconPart;
  const [showDetail, setShowDetail] = React.useState(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail(true);
  }, []);
  const handleHideDetail = React.useCallback(() => {
    setShowDetail(false);
  }, []);
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} className="service-item-image flex-grow-1" />;
  }

  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="service-item-image flex-grow-1" fileName={imageFileName} />;
  }
  return (
    <div className="service-item px-4 h-100 d-flex flex-column justify-content-between">
      {iconPart}
      {imagePart}
      <h4 className="service-item-heading mt-5">{header}</h4>
      <p className="text-muted mt-4 text-wrap">
        {content.length > 120 ? `${content.substring(0, 120)}...` : content}
      </p>
      <button
        type="button"
        className="text-uppercase btn btn-primary btn-l mt-3 mb-2"
        data-toggle="modal"
        onClick={handleShowDetail}
      >
        {readMore}
      </button>
      <PortfolioDetailDialog
        show={showDetail}
        onHide={handleHideDetail}
        imageFileName={imageFileName}
        iconName={iconName}
        // imageAlt={imageAltDetail || imageAlt}
        header={header}
        content={content}
        extraInfo={[""]}
      />
    </div>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  readMore: PropTypes.string,
};

ServiceItem.defaultProps = {
  iconName: null,
  imageFileName: null,
  header: "",
  content: "",
  readMore: "",
};

export default ServiceItem;
