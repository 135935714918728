import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header,
    subheader,
    telephone,
    email,
    addressUrl,
    addressText,
    whatsapp,
    whatsappText,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={4} className="ms-auto text-center">
          <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
          <a className="d-block" href={`tel:${telephone}`}>
            <span className="btn btn-primary">{telephone}</span>
          </a>
        </Col>
        <Col lg={4} className="me-auto text-center">
          <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
          <a className="d-block" href={`mailto:${email}`}>
            <span className="btn btn-primary">{email.replace(/@/, " [at] ")}</span>
          </a>
        </Col>
        <Col lg={4} className="me-auto text-center">
          <Icon iconName="MapMarkerIcon" size="3x" className="text-muted mb-3" />
          <a className="d-block" href={`${addressUrl}`} target="_blank" rel="noreferrer">
            <span className="btn btn-primary">{addressText}</span>
          </a>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={12} className="me-auto text-center">
          <a
            className="d-flex align-items-center justify-content-center text-decoration-none text-secondary"
            href={whatsapp}
            target="_blank"
            rel="noreferrer"
          >
            <Icon iconName="WhatsappIcon" size="3x" className="text-muted pr-5" />
            <span className="ms-2 text-uppercase">{whatsappText}</span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
