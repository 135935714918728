import React from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// eslint-disable-next-line import/no-extraneous-dependencies
import "leaflet/dist/leaflet.css";

const ContactMap = ({ addressUrl }) => {
  const defaultProps = {
    name: "Casa di riposo la Meridienne",
    rating: 5,
    price_level: 2,
    opening_hours: {
      open_now: true,
    },
    center: {
      lat: 41.8292495,
      lng: 12.9079521,
    },
    marker: {
      lat: 41.82901626285065,
      lng: 12.90863565903937,
    },
    zoom: 17.04,
  };

  const CustomPopup = React.memo(function a() {
    return (
      <div>
        <div style={{ fontSize: "16px" }}>{defaultProps.name}</div>
        <div style={{ fontSize: "14px" }}>
          <span style={{ color: "orange" }}>
            {String.fromCharCode(9733).repeat(Math.floor(defaultProps.rating))}
          </span>
          <span style={{ color: "lightgrey" }}>
            {String.fromCharCode(9733).repeat(5 - Math.floor(defaultProps.rating))}
          </span>
        </div>
        <div style={{ fontSize: "12px", color: "gray" }}>
          {Array(defaultProps.price_level)
            .fill("")
            .reduce((acc) => acc.concat("€"), "")}
        </div>
        <div style={{ fontSize: "14px", color: "green" }}>
          {defaultProps.opening_hours.open_now ? "Open" : "Closed"}
        </div>
        <div style={{ fontSize: "14px", color: "black", marginTop: "10px" }}>
          <a href={addressUrl} target="_blank" rel="noreferrer">
            Ottieni indicazioni
          </a>
        </div>
      </div>
    );
  });

  return (
    <MapContainer
      center={defaultProps.center}
      zoom={defaultProps.zoom}
      scrollWheelZoom={false}
      style={{ height: "50vh", width: "100%", zIndex: 0 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={defaultProps.marker}>
        <Popup>
          <CustomPopup />
        </Popup>
      </Marker>
    </MapContainer>
  );
};

ContactMap.propTypes = {
  addressUrl: PropTypes.string.isRequired,
};

export default ContactMap;
