/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ lang, description, title, socialCard }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );
  const siteUrl = "https://preview.meridienne.it";

  const metaTitle = title || site.siteMetadata.title;
  const favicons = [
    {
      rel: "icon",
      href: "/assets/images/favicons/favicon.ico",
    },
    {
      rel: "icon",
      sizes: "16x16",
      type: "image/png",
      href: "/assets/images/favicons/favicon-16x16.png",
    },
    {
      rel: "icon",
      sizes: "32x32",
      type: "image/png",
      href: "/assets/images/favicons/favicon-32x32.png",
    },
    {
      rel: "apple-touch-icon",
      href: "/assets/images/favicons/apple-icon.png",
    },
  ];

  const socialCardContent = socialCard
    ? `${siteUrl}/assets/images/social-cards/${socialCard}`
    : `${siteUrl}/assets/images/social-cards/social.jpeg`;

  const twitter = [
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { name: "twitter:site", content: "@meridienne" },
    { name: "twitter:image", content: socialCardContent },
  ];

  const facebook = [
    { name: "og:title", content: title },
    { name: "og:description", content: description },
    { name: "og:type", content: "website" },
    { name: "og:locale", content: "it_IT" },
    { name: "og:image", content: socialCardContent },
    { name: "og:site_name", content: metaTitle },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[...favicons]}
      meta={[
        { name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no" },
        { name: "description", content: description },
        { httpEquiv: "X-UA-Compatible", content: "IE=Edge" },
        ...twitter,
        ...facebook,
      ]}
    />
  );
};

SEO.propTypes = {
  lang: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  socialCard: PropTypes.string,
  // meta: PropTypes.arrayOf(PropTypes.object),
  // keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  // meta: [],
  // keywords: [],
  socialCard: "",
};

export default SEO;
