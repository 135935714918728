import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import "./WhatsappCTA.scss";

const WhatsappCTA = ({ link, text }) => (
  <a
    className="d-flex justify-content-end text-decoration-none"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="d-none d-md-block whatsapp-text">{text}</div>
    <div className="whatsapp-icon" aria-label="WhatsappIcon">
      <Icon iconName="WhatsappIcon" />
    </div>
  </a>
);

WhatsappCTA.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default WhatsappCTA;
